// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--layout-results-split-left-width:24rem}.container_cgl4a,.filters_aUuZb,.filtersToggle_tkmbE,.left_yzChD,.right_b-L3m,.rightBottom_NSanb,.rightTop_kaH9D{display:flex}.container_cgl4a{flex-grow:1;overflow-x:auto;position:relative;width:100%}.left_yzChD{flex-direction:column;height:100%;padding:1rem;position:fixed;width:24rem;width:var(--layout-results-split-left-width)}.filtersToggle_tkmbE{justify-content:center}.filters_aUuZb{box-shadow:var(--shadow-medium);margin-bottom:5rem}.filters_aUuZb,.right_b-L3m{flex-direction:column}.right_b-L3m{margin-left:24rem;margin-left:var(--layout-results-split-left-width);width:calc(100% - 24rem);width:calc(100% - var(--layout-results-split-left-width))}.rightBottom_NSanb,.rightTop_kaH9D{flex-direction:column}.rightTop_kaH9D{align-items:flex-start}.rightBottom_NSanb{align-items:center;padding-bottom:1rem}.controls_sKgD4,.controlsEnd_tv8ki,.controlsStart_tiw7s,.header_dW0AX,.mapToggle_FJBn3,.results_4n7TQ{display:flex}.header_dW0AX{padding:1rem 1rem 0}.controls_sKgD4{align-items:flex-end;justify-content:space-between}.controls_sKgD4,.results_4n7TQ{padding:1rem;width:100%}.results_4n7TQ{flex-direction:column}.overflow_E7lh6{-webkit-overflow-scrolling:touch;overflow:auto;scroll-behavior:smooth;scrollbar-color:var(--color-supportive-3-400) var(--color-supportive-3-100);scrollbar-width:thin}.overflow_E7lh6::-webkit-scrollbar{height:1rem}.overflow_E7lh6::-webkit-scrollbar-track{background:var(--color-supportive-3-100)}.overflow_E7lh6::-webkit-scrollbar-thumb{background-color:var(--color-supportive-3-400);border:.25rem solid var(--color-supportive-3-100);border-radius:calc(1rem - var(--border-radius-medium))}.overflow_E7lh6::-webkit-scrollbar-thumb:hover{border-width:.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_cgl4a",
	"filters": "filters_aUuZb",
	"filtersToggle": "filtersToggle_tkmbE",
	"left": "left_yzChD",
	"right": "right_b-L3m",
	"rightBottom": "rightBottom_NSanb",
	"rightTop": "rightTop_kaH9D",
	"controls": "controls_sKgD4",
	"controlsEnd": "controlsEnd_tv8ki",
	"controlsStart": "controlsStart_tiw7s",
	"header": "header_dW0AX",
	"mapToggle": "mapToggle_FJBn3",
	"results": "results_4n7TQ",
	"overflow": "overflow_E7lh6"
};
module.exports = ___CSS_LOADER_EXPORT___;
